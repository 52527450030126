:root{
    --main-color: #20639b;
}
.text-main{
    color: var(--main-color);
}
.bg-main{
    background-color: var(--main-color);
}
.border-main{
    border-color: var(--main-color) !important;
}
*{
    font-family: 'Poppins', sans-serif;
}
h1,h2,.h1,.h2{
    font-family: 'Raleway', sans-serif;
}

html {
  scroll-behavior: smooth;
  transition: 0.2s;
}





#homepage_header{
    background-image: url(/img/votare.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    
    .box_title{
        height: 10vh;
        background-color:rgba($color: #20639b, $alpha: 0.7);
        color: rgb(255, 255, 255);
        span{
            color: white;
        }
    }
}
li{
        list-style: none;
}
#panel_nav{
    a{
        color: black;
    }
    a:hover {
        color:white;
        background-color: rgba(3,97,145,1)!important;
        -moz-box-shadow: 6px 2px 6px rgba(3,97,145,1)!important;
        -webkit-box-shadow: 6px 2px 6px rgba(3,97,145,1)!important;
        box-shadow: 2px 2px 2px rgba(3,97,145,1);
        text-decoration: underline;
    }
    a:active {
        color: rgb(10, 10, 9);
        background-color: transparent;
        text-decoration: underline;
    }
}
#panel{
    min-height: 100vh;
    li{
        list-style: none;
    }
    a:link {
        color: white;
        background-color: transparent;
        
    }
    a:visited {
        color: rgb(255, 253, 253);
        background-color: transparent;
    }
    
    a:hover {
        color: rgb(246, 255, 0);
        background-color: rgba(0, 0, 0, 0.402);
        -moz-box-shadow: 6px 2px 6px rgba(0,0,0,.5);
        -webkit-box-shadow: 6px 2px 6px rgba(0,0,0,.5);
        box-shadow: 6px 2px 6px rgba(0,0,0,.5);
        text-decoration: underline;
    }
    a:active {
        color: yellow;
        background-color: transparent;
        text-decoration: underline;
    }
    #collaborators, #voting{
        color: rgb(251, 251, 250);
        background-color: rgba(0, 0, 0, 0.402) !important;
        -moz-box-shadow: 6px 2px 6px rgba(0,0,0,.5) !important;
        -webkit-box-shadow: 6px 2px 6px rgba(0,0,0,.5) !important;
        box-shadow: 6px 2px 6px rgba(0,0,0,.5)!important;
        text-decoration: none;
        cursor: default;
    }
}
.center{
        width: 50%;
        transform: translate(50%,20%);
}
