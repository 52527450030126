// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// DateTime Picker
@import '~jquery-datetimepicker/jquery.datetimepicker.css';

@import 'style.scss';
